@import url(./inter.less);
@import url(./diatype.less);

html {
  height: 100%;
}

body {
  font-family: Diatype;
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
}

.ant-app {
  height: 100%;
}

@hack: true; @import "~@/less/index.less";