/* static fonts */
@font-face {
  font-family: 'Diatype';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('@/assets/diatype/ABCDiatypeMono-Thin.woff2') format('woff2');
}
@font-face {
  font-family: 'Diatype';
  font-style: italic;
  font-weight: 100 200;
  font-display: swap;
  src: url('@/assets/diatype/ABCDiatypeMono-ThinItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'Diatype';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('@/assets/diatype/ABCDiatypeMono-Light.woff2') format('woff2');
}
@font-face {
  font-family: 'Diatype';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('@/assets/diatype/ABCDiatypeMono-LightItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'Diatype';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('@/assets/diatype/ABCDiatypeMono-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Diatype';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('@/assets/diatype/ABCDiatypeMono-RegularItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'Diatype';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('@/assets/diatype/ABCDiatypeMono-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'Diatype';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('@/assets/diatype/ABCDiatypeMono-MediumItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'Diatype';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('@/assets/diatype/ABCDiatypeMono-Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'Diatype';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('@/assets/diatype/ABCDiatypeMono-BoldItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'Diatype';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('@/assets/diatype/ABCDiatypeMono-Heavy.woff2') format('woff2');
}
@font-face {
  font-family: 'Diatype';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('@/assets/diatype/ABCDiatypeMono-HeavyItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'Diatype';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('@/assets/diatype/ABCDiatypeMono-Black.woff2') format('woff2');
}
@font-face {
  font-family: 'Diatype';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url('@/assets/diatype/ABCDiatypeMono-BlackItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'Diatype';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('@/assets/diatype/ABCDiatypeMono-Ultra.woff2') format('woff2');
}
@font-face {
  font-family: 'Diatype';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('@/assets/diatype/ABCDiatypeMono-UltraItalic.woff2') format('woff2');
}